import React, { useReducer } from "react";
import DA from '../locales/da';
import EN from '../locales/en';
import SV from '../locales/sv';


const getLinkPrefix = (locale) => {
  switch (locale){
    case 'da-DK':
      return 'da/'
    case 'sv-SE':
      return 'sv/'
    default:
      return ''
  }
}

const getContent = (locale) => {
  switch (locale){
    case 'da-DK':
      return DA
    case 'sv-SE':
      return SV
    default:
      return EN
  }
}


export const defaultInterfaceContext = {
  isGridView: true,
  translationLinks: {},
  locale: "en-US",
  linkPrefix: '',
  content: getContent("en-US")
}


export const InterfaceContext = React.createContext(defaultInterfaceContext)

export default React.createContext(defaultInterfaceContext)

const reducer = (state, action) => {
  switch (action.type) {
    case "setAppearance":
      return {
        ...state,
        appearance: action.payload,
      }
    case "setLocale":
      return {
        ...state,
        locale: action.payload,
        linkPrefix: getLinkPrefix(action.payload),
        content: getContent(action.payload),
      }
    case "setTranslationLinks":
      return {
        ...state,
        translationLinks: action.payload,
      }
    case "setGridView":
      return {
        ...state,
        isGridView: action.payload,
      }
    case "toggleGridView":
      return {
        ...state,
        isGridView: !state.isGridView,
      }
    case "setHoverCase":
      return {
        ...state,
        hoverCase: action.payload,
        isGridView: action.payload ? false : true, // When hovering a case, switch to list view
      }
    case "setDisplayCase":
      return {
        ...state,
        displayCase: action.payload,
      }
  }
} 

export const InterfaceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultInterfaceContext)

  const setGridView = (isGridView) => {
    dispatch({ type: "setGridView", payload: isGridView })
  }

  const toggleGridView = () => {
    dispatch({ type: "toggleGridView" })
  }

  const setLocale = (locale) => {
    dispatch({ type: "setLocale", payload: locale })
  }

  const setAppearance = (mode) => {
    dispatch({ type: "setAppearance", payload: mode })
  }

  const setTranslationLinks = (links) => {
    dispatch({ type: "setTranslationLinks", payload: links })
  }

  const setDisplayCase = (slug) => {
    dispatch({ type: "setDisplayCase", payload: slug })
  }

  const setHoverCase = (slug) => {
    dispatch({ type: "setHoverCase", payload: slug })
  }

  return (
    <InterfaceContext.Provider
      value={{
        ...state,
        setGridView,
        setHoverCase,
        setDisplayCase,
        setAppearance,
        setLocale,
        setTranslationLinks,
        toggleGridView,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  )
}
